.ordinance-wrapper {
  padding: 15px;
}
.ordinance-wrapper p,
.rozp p,
.journalImplementation p {
  font-size: 16px;
  margin-top: 11px;
}
.rozp {
  padding-bottom: 25px;
  text-align: justify;
}
.rozp h2 {
  font-size: 28px;
  text-align: center;
  margin: 20px 0;
  color: var(--hcolor);
}
div.rozp div div div {
  margin-left: 2em;
}
blockquote {
  margin: 2em 0 1em 0;
  padding: 1em 2.8em;
  font-style: italic;
  font-size: 1em;
  background-color: #fff;
  border: 1px solid #ccc;
}
.warn {
  border: 1px solid #ffd987;
  border-left: 5px solid #ffd987;
  background: rgba(254, 246, 221, 0.3);
  margin: 15px;
  padding: 0.5em 1.5em;
}
.reviews {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 330px));
  justify-content: center;
  gap: 10px;
  padding: 8px;
  margin-bottom: 15px;
}
.reviews .review {
  margin-bottom: 15px;
  padding: 80px 20px 20px;
}
.journalImplementation {
  padding: 20px 10px;
}
.journalImplementation ul {
  list-style: disc;
}
.journalImplementation h2,
.journalImplementation h3,
.journalImplementation h1 {
  color: var(--hcolor);
}
.journalImplementation h2 {
  font-size: 22px;
}
.journalImplementation h3 {
  margin-top: 10px;
  font-size: 19px;
}
.journalImplementation ol,
.journalImplementation ul {
  padding: 0 0 0 25px;
  margin: 1em;
  font-size: 16px;
}
.journalImplementation i {
  font-size: 35px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 3%;
}
.journalImplementation .download {
  display: inline-block;
  margin: 1em 0;
  -moz-box-shadow: 0 3px rgba(0, 0, 0, 0.1), inset 0 -4px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 3px rgb(0 0 0 / 10%), inset 0 -4px rgb(0 0 0 / 10%);
  box-shadow: 0 3px rgb(0 0 0 / 10%), inset 0 -4px rgb(0 0 0 / 10%);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  padding: 0.7em 1em 0.7em 3.2em;
  color: white !important;
  overflow: visible;
  background: #58a100;
  background: linear-gradient(top, #84c63c 0, #489615 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#84C63C',endColorstr='#489615',GradientType=0);
  -moz-transition: box-shadow 0.2s ease-in-out;
  -webit-transition: box-shadow 0.2s ease-in-out;
  transition: box-shadow 0.2s ease-in-out;
  zoom: 1;
  font-size: 1.2em;
  font-weight: bold;
  text-align: left;
  position: relative;
  border-bottom: none !important;
}
.writtenContract p {
  font-size: 17px;
  margin: 13px 0;
}
.writtenContract h2 {
  font-size: 24px;
  color: var(--hcolor);
}
.writtenContract ul {
  list-style: disc;
  padding-left: 45px;
  font-size: 17px;
}
.hidden-email i {
  position: relative;
  font-size: 1em;
  margin-right: 4px;
}
@media (max-width: 550px) {
  .journalImplementation .download {
    font-size: 15px;
    padding: 0.7em 1em 0.7em 3.8em;
  }
  .writtenContract h2 {
    font-size: 21px;
  }
  .writtenContract ul {
    padding-left: 25px;
  }
  .rozp__img > img {
    width: 100%;
  }
  .rozp h2 {
    font-size: 22px;
  }
}
