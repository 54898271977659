:root {
  --hcolor: #4e4c4c;
}
ul.menu {
  z-index: 30;
}
a {
  color: #767676 !important;
  transition: color 0.4s;
}
.hidden-email {
  font-weight: 700;
}
a:hover,
a:focus {
  color: red !important;
}
.banner__clean {
  height: auto;
}
h1,
h2,
h3 {
  margin: 12px;
}
section {
  padding: 20px 10px;
}
.contact {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.contact__link {
  color: #c00202;
}
.contact__title {
  width: 100%;
}
.text__center {
  text-align: center;
}
.contact__box {
  width: 100%;
  max-width: 510px;
  margin: 20px auto 70px;
  padding: 20px;
  background-color: #f7f7f7;
}
.contact__data {
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 18px;
}
.contact__name {
  font-size: 23px;
  font-weight: 700;
  color: #333;
}
.contact__position {
  display: block;
  margin-bottom: 15px;
}
.contact__img {
  float: left;
  margin-right: 30px;
}
.column__header--highlight {
  color: #ed2b34;
}
.column__text--narrow {
  width: 75%;
  margin-bottom: 0;
}
.column--full {
  width: 100%;
}
.download__box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 30px;
  padding: 30px;
  background-color: #fff;
}
.download {
  margin-bottom: 30px;
  padding: 30px 30px 1px;
  background-color: #f7f7f7;
}
.download__img {
  border: 1px solid black;
}
.download__title {
  margin-bottom: 30px;
  font-size: 17px;
  font-weight: 700;
  text-transform: uppercase;
}
.download__code {
  width: 65%;
  padding: 15px;
  border: 1px solid #767676;
}
.info-box {
  width: 100%;
  max-width: 700px;
  margin: 50px auto;
  padding: 50px;
  background-color: #f7f7f7;
}
.info-box__header {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 700;
  color: #ed2b34;
}
.info-box__text {
  margin-bottom: 20px;
  font-size: 16px;
  color: #333;
}
.features__box {
  width: calc(50% - 30px);
  margin-bottom: 30px;
  padding: 30px;
  background-color: #fff;
}
.features__title {
  font-size: 17px;
  margin-bottom: 10px;
  font-weight: 700;
  text-transform: uppercase;
  color: #333;
}
.features {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 35px 0;
  padding: 35px 15px 5px;
  background-color: #f7f7f7;
}
.features__points li {
  position: relative;
  padding-left: 15px;
  line-height: 20px;
}
.features__points li:before {
  content: "";
  width: 5px;
  height: 5px;
  display: block;
  position: absolute;
  top: 5px;
  left: -10px;
  background-color: #ed2b34;
}
.price-box {
  margin: 0 50px;
  padding: 40px;
  text-align: center;
  background-color: #f7f7f7;
}
.price-box__header {
  margin-bottom: 20px;
  font-size: 30px;
  color: #ed2b34;
}
.movies {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 330px));
  text-align: center;
  justify-content: center;
  margin: 30px 0 60px 0;
  gap: 40px 10px;
}
.movie {
  padding-bottom: 15px;
}
.movies .movie iframe {
  width: 80%;
  height: 100%;
}
.carousel {
  /* height: 250px; */
  display: none;
}
.footer__top a {
  color: white !important;
}
.highlight {
  color: red !important;
}
@media screen and (min-width: 767px) {
  .movies {
    justify-content: center;
  }
}
@media screen and (max-width: 767px) {
  .column__text--narrow {
    width: 100%;
  }
}
@media (max-width: 580px) {
  .carousel {
    height: auto;
  }
  .flex {
    flex-direction: column;
  }
  .flex .features__box {
    width: auto;
  }
  .price-box {
    margin: 0;
    padding: 20px;
  }
  .contact__data {
    font-size: 16px;
  }
  .contact__name {
    font-size: 20px;
  }
}
